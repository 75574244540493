import {
  map, cloneDeep, find, reduce, pickBy, identity,
} from 'lodash';
import { useQuery } from '@vue/apollo-composable';
import { endOfDay, format, parseISO } from 'date-fns';
import gql from 'graphql-tag';
import apolloClient from '@/services/apollo-client';
import sportIds from '@/services/helpers/sports';
import computeStartsAt from '@/utils/computeStartsAt';
import allEventsQuery from './allEvents.query.gql';
import allRegionsQuery from './allRegions.query.gql';
import allCompetitionsQuery from './allCompetitions.query.gql';
import allCompetitionsSearchQuery from './allCompetitionsSearch.query.gql';
import allBaseballPlayersQuery from './allBaseballPlayers.query.gql';
import allBasketballPlayersQuery from './allBasketballPlayers.query.gql';
import allBaseballParksQuery from './allBaseballParks.query.gql';
import allFootballPlayersQuery from './allFootballPlayers.query.gql';
import allSoccerPlayersQuery from './allSoccerPlayers.query.gql';
import allUltimatePlayersQuery from './allUltimatePlayers.query.gql';
import allHockeyPlayersQuery from './allHockeyPlayers.query.gql';
import allExtHockeyPlayersQuery from './allExtHockeyPlayers.query.gql';
import allSportsQuery from './allSports.query.gql';
import eventQuery from './event.query.gql';
import eventPlayerSetupQuery from './eventPlayerSetup.query.gql';
import eventMarketsQuery from './eventMarkets.query.gql';
import allEventsMappingQuery from './allEventsMapping.query.gql';
import allExtEventsMappingQuery from './allExtEventsMappingQuery.query.gql';
import allSoccerTeamsQuery from './allSoccerTeams.query.gql';
import allBasketballTeamsQuery from './allBasketballTeams.query.gql';
import allBaseballTeamsQuery from './allBaseballTeams.query.gql';
import allUltimateTeamsQuery from './allUltimateTeams.query.gql';
import allFootballTeamsQuery from './allFootballTeams.query.gql';
import allHockeyTeamsQuery from './allHockeyTeams.query.gql';
import allExtBasketballTeamsQuery from './allExtBasketballTeams.query.gql';
import allExtSoccerTeamsQuery from './allExtSoccerTeams.query.gql';
import allExtFootballTeamsQuery from './allExtFootballTeams.query.gql';
import allExtBaseballTeamsQuery from './allExtBaseballTeams.query.gql';
import allExtUltimateTeamsQuery from './allExtUltimateTeams.query.gql';
import allExtRegionsQuery from './allExtRegions.query.gql';
import allExtCompetitionsQuery from './allExtCompetitions.query.gql';
import allExtBaseballPlayersQuery from './allExtBaseballPlayers.query.gql';
import allExtBasketballPlayersQuery from './allExtBasketballPlayers.query.gql';
import allExtFootballPlayersQuery from './allExtFootballPlayers.query.gql';
import allExtSoccerPlayersQuery from './allExtSoccerPlayers.query.gql';
import allExtUltimatePlayersQuery from './allExtUltimatePlayers.query.gql';
import allExtHockeyTeamsQuery from './allExtHockeyTeams.query.gql';
import multiFeedsQuery from './multiFeeds.query.gql';
import sidebarRegionsQuery from './sidebarRegions.query.gql';
import sidebarCompetitionsQuery from './sidebarCompetitions.query.gql';
import sportsQuery from './sports.gql';
import allSportTemplatesQuery from './allSportTemplates.query.gql';
import sportTemplateQuery from './sportTemplate.query.gql';
import sportTemplateEnabledQuery from './sportTemplateEnabled.query.gql';
import allMarketTemplatesQuery from './allMarketTemplates.query.gql';
import allMarketGroupsQuery from './allMarketGroups.query.gql';
import allCompetitionTemplatesQuery from './allCompetitionTemplates.query.gql';
import allFeedsQuery from './allFeeds.query.gql';
import marketGroupsQuery from './marketGroups.query.gql';
import appliedTemplatesQuery from './appliedTemplates.query.gql';
import getAllCompetitionsQuery from './getAllCompetitions.query.gql';
import getCompetitionsBetweenQuery from './getCompetitionsBetween.query.gql';
import manualResultingEventsQuery from './manualResultingEvents.query.gql';
import manualResultingEventMarketsQuery from './manualResultingEventMarkets.query.gql';
import eventMappingDetailsQuery from './eventMappingDetails.query.gql';
import eventMappingDetailsExtQuery from './eventMappingDetailsExt.query.gql';
import eventSubscriptionQuery from './eventSubscription.query.gql';
import competitionSubscriptionQuery from './competitionSubscription.query.gql';
import allEventsSearchQuery from './allEventsSearch.query.gql';
import basketballTeamByTeamIdQuery from './basketballTeamByTeamId.query.gql';
import footballTeamByTeamIdQuery from './footballTeamByTeamId.query.gql';
import hockeyTeamByTeamIdQuery from './hockeyTeamByTeamId.query.gql';
import baseballTeamByTeamIdQuery from './baseballTeamByTeamId.query.gql';
import tradingEventQuery from './tradingEvent.query.gql';
import allOperatorsQuery from './allOperators.query.gql';
import feedsPerSportQuery from './feedsPerSport.query.gql';
import tradingMarketDisplayConfigurationsQuery from './allTradingMarketDisplayConfigurations.query.gql';
import multiviewEventsQuery from './multiviewEvents.query.gql';
import multiviewSearchQuery from './multiviewSearch.query.gql';
import multiviewDrawerQuery from './multiviewDrawer.query.gql';
import allMarketDisplayConfigurationsQuery from './allMarketDisplayConfigurations.query.gql';
import allSelectionDisplayConfigurationsQuery from './allSelectionDisplayConfigurations.query.gql';
import allSportsMarketDisplayConfigurationsQuery from './allSportsMarketDisplayConfigurations.query.gql';
import allMarketParametersQuery from './allMarketParameters.query.gql';
import allSelectionParametersQuery from './allSelectionParameters.query.gql';
import feedConfigurationQuery from './feedConfiguration.query.gql';
import allMarketGroupsListQuery from './allMarketGroupsList.query.gql';
import allMarketCodesQuery from './allMarketCodes.query.gql';
import sportCompetitionsWithEventQuery from './sportCompetitionsWithEvent.query.gql';
import oddsCheckerEventsQuery from './oddsCheckerEvents.query.gql';
import primarySportFeeds from './primarySportFeeds.query.gql';
import sportCompetitionConfigurations from './sportCompetitionConfigurations.query.gql';
import editMarketListQuery from './editMarketList.query.gql';
import sportsAndCompetitionsQuery from './sportsAndCompetitions.query.gql';
import sportByIdQuery from './sportById.query.gql';
import competitionsQuery from './competitions.query.gql';
import competitionByIdQuery from './competitionById.query.gql';
import userConfigurationQuery from './userConfiguration.query.gql';
import allCompetitionsByIdQuery from './allCompetitionsById.query.gql';
import competitionsBySportQuery from './competitionsBySport.query.gql';

// Manual edit
import allManualEditFootballTeamsQuery from './allManualEditFootballTeams.query.gql';
import allManualEditBaseballTeamsQuery from './allManualEditBaseballTeams.query.gql';
import allManualEditBasketballTeamsQuery from './allManualEditBasketballTeams.query.gql';
import allManualEditHockeyTeamsQuery from './allManualEditHockeyTeams.query.gql';
import allManualEditUltimateTeamsQuery from './allManualEditUltimateTeams.query.gql';

import allManualEditFootballPlayersQuery from './allManualEditFootballPlayers.query.gql';
import allManualEditBaseballPlayersQuery from './allManualEditBaseballPlayers.query.gql';
import allManualEditBasketballPlayersQuery from './allManualEditBasketballPlayers.query.gql';
import allManualEditHockeyPlayersQuery from './allManualEditHockeyPlayers.query.gql';
import allManualEditUltimatePlayersQuery from './allManualEditUltimatePlayers.query.gql';

// Manual event creation
import simpleFootballTeamsQuery from './simpleFootballTeams.query.gql';
import simpleBaseballTeamsQuery from './simpleBaseballTeams.query.gql';
import simpleBasketballTeamsQuery from './simpleBasketballTeams.query.gql';
import simpleHockeyTeamsQuery from './simpleHockeyTeams.query.gql';

// Audit log
import auditLogEventQuery from './auditLogEvent.query.gql';

const {
  SOCCER_ID,
  BASKETBALL_ID,
  FOOTBALL_ID,
  BASEBALL_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
} = sportIds;

const createQuery = (query) => (
  queryOptions = {},
  fetchPolicy = {},
) => useQuery(
  query,
  queryOptions,
  {
    notifyOnNetworkStatusChange: true,
    ...fetchPolicy,
  },
);

export const useEventsQuery = createQuery(allEventsQuery);

export const findEvents = (variables = {}) => apolloClient
  .query({
    query: allEventsQuery,
    variables,
    fetchPolicy: 'network-only',
  })
  .then((response) => response.data?.events || []);

export const findBookedEvents = (variables = {}) => {
  const modifiedVariables = cloneDeep(variables);
  return findEvents(modifiedVariables);
};

export const findSportTemplates = (variables = {}) => apolloClient
  .query(
    {
      query: allSportTemplatesQuery,
      variables,
      fetchPolicy: 'network-only',
      context: {
        requestTrackerId: 'getAllSportTemplates',
      },
    },
  )
  .then((response) => response.data?.sportTemplates?.nodes || []);

export const findSportTemplateById = (id) => apolloClient
  .query({ query: sportTemplateQuery, variables: { id }, fetchPolicy: 'network-only' })
  .then((response) => response.data.sportTemplate);

export const findSportTemplateByIdAndAvailableFeeds = (id) => apolloClient
  .query({ query: sportTemplateQuery, variables: { id }, fetchPolicy: 'network-only' })
  .then((response) => response.data);

export const findSportTemplateEnabled = ({ id, sportLogicTemplateIds }) => apolloClient
  .query({ query: sportTemplateEnabledQuery, variables: { id, sportLogicTemplateIds }, fetchPolicy: 'network-only' })
  .then((response) => response.data.sportTemplate);

export const findMarketTemplates = (variables = {}) => apolloClient
  .query({ query: allMarketTemplatesQuery, variables })
  .then((response) => response.data?.marketTemplates?.nodes || []);

export const getMarketGroups = (variables = {}) => apolloClient
  .query({ query: allMarketGroupsQuery, variables })
  .then((response) => response.data?.marketGroups?.nodes || []);

export const useRegionsQuery = ({ queryOptions }) => apolloClient
  .query({ query: allRegionsQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const useExtRegionsQuery = ({ queryOptions }) => apolloClient
  .query({ query: allExtRegionsQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const useCompetitionsQuery = ({ queryOptions }) => apolloClient
  .query({ query: allCompetitionsQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const useSearchCompetitionsQuery = (variables) => apolloClient
  .query({ query: allCompetitionsSearchQuery, variables, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const useExtCompetitionsQuery = ({ queryOptions }) => apolloClient
  .query({ query: allExtCompetitionsQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const usePlayersQuery = ({ sportId, queryOptions }) => {
  let query = null;
  if (sportId === SOCCER_ID) query = allSoccerPlayersQuery;
  else if (sportId === BASKETBALL_ID) query = allBasketballPlayersQuery;
  else if (sportId === FOOTBALL_ID) query = allFootballPlayersQuery;
  else if (sportId === BASEBALL_ID) query = allBaseballPlayersQuery;
  else if (sportId === ULTIMATE_ID) query = allUltimatePlayersQuery;
  else if (sportId === HOCKEY_ID) query = allHockeyPlayersQuery;
  else return Promise.resolve([]);
  return apolloClient
    .query({ query, variables: queryOptions, fetchPolicy: 'network-only' })
    .then((response) => response)
    .catch((err) => err);
};

export const useExtPlayersQuery = ({ sportId, queryOptions }) => {
  let query = null;
  if (sportId === SOCCER_ID) query = allExtSoccerPlayersQuery;
  else if (sportId === BASKETBALL_ID) query = allExtBasketballPlayersQuery;
  else if (sportId === FOOTBALL_ID) query = allExtFootballPlayersQuery;
  else if (sportId === BASEBALL_ID) query = allExtBaseballPlayersQuery;
  else if (sportId === ULTIMATE_ID) query = allExtUltimatePlayersQuery;
  else if (sportId === HOCKEY_ID) query = allExtHockeyPlayersQuery;
  else return Promise.resolve([]);
  return apolloClient
    .query({ query, variables: queryOptions, fetchPolicy: 'network-only' })
    .then((response) => response)
    .catch((err) => err);
};

export const useSportsQuery = createQuery(allSportsQuery);

export const fetchSubscriptionsSidebar = ({ sportId, regionId }) => {
  const initSidebarQuery = gql`
    query {
      sports: allSports(
        orderBy: SPORT_LABEL_ASC
        filter: {
          sportCoverageAvailablesBySportId: {
            some: {
              available: {
                equalTo: true
              }
            }
          }
        }
        condition: {
          limbo: false
        }
        ) {
        nodes {
          sportId
          sportName
          sportLabel
        }
      }

      ${
        sportId
          ? `
            regions: allRegions(
              filter: {
                competitionsByRegionId: {
                  some: {
                    sportId: {
                      equalTo: "${sportId}"
                    }
                  }
                }
                limbo: { equalTo: false }
                regionCoverageAvailablesByRegionId: {
                  some: {
                    available: {
                      equalTo: true
                    }
                  }
                }
              }
              orderBy: REGION_NAME_ASC
            ) {
              nodes {
                regionId
                regionName
              }
            }
          `
          : ''
      }

      ${
        sportId && regionId
          ? `
            competitions: allCompetitions(
                condition: {
                    sportId: "${sportId}",
                    regionId: ${regionId === '-' ? null : `"${regionId}"`},
                }
                orderBy: COMPETITION_NAME_ASC
                filter: {
                  limbo: { equalTo: false }
                  competitionCoverageAvailablesByCompetitionId: {
                    some: {
                      available: {
                        equalTo: true
                      }
                    }
                  }
                }
            ) {
                nodes {
                    competitionId
                    competitionName
                }
            }
          `
          : ''
      }
    }
  `;

  return apolloClient
    .query({
      query: initSidebarQuery,
      fetchPolicy: 'network-only',
    })
    .then((response) => {
      const payload = {
        sports: response.data.sports?.nodes || [],
        regions: response.data.regions?.nodes || [],
        competitions: response.data.competitions?.nodes || [],
      };

      if (sportId) {
        payload.regions = [
          ...payload.regions,
          {
            regionId: '-',
            regionName: 'Unspecified',
          },
        ];
      }

      return payload;
    });
};

export const findAllSports = () => apolloClient
  .query({
    query: sportsQuery,
    variables: {},
    fetchPolicy: 'network-only',
  })
  .then((response) => [
    {
      sportId: '',
      sportLabel: 'ALL_SPORTS',
      sportName: 'All sports',
      limbo: false,
      sportCoverageAvailablesBySportId: {
        nodes: [
          {
            available: true,
          },
        ],
      },
    },
    ...(response.data.sports?.nodes || []),
  ]);

export const findEventById = (id) => apolloClient
  .query({
    query: eventQuery,
    variables: {
      id,
    },
    context: {
      requestTrackerId: 'findEventById',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response.data.event);

export const findEventByIdForPlayerSetup = async (id) => {
  try {
    const response = await apolloClient
      .query({
        query: eventPlayerSetupQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      });
    return response.data.event;
  } catch (err) {
    console.log('err');
    throw Error(err);
  }
};

export const findEventMarketsById = (params) => apolloClient
  .query({ query: eventMarketsQuery, variables: { id: params.id }, fetchPolicy: 'network-only' })
  .then((response) => response?.data?.event?.markets?.nodes || []);

export const useEventsMappingQuery = (queryOptions) => apolloClient
  .query({
    query: allEventsMappingQuery,
    variables: queryOptions,
    context: {
      requestTrackerId: 'queryAllEventsMapping',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const useExtEventsMappingQuery = (queryOptions) => apolloClient
  .query({
    query: allExtEventsMappingQuery,
    variables: queryOptions,
    context: {
      requestTrackerId: 'queryAllExtEventsMapping',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response)
  .catch((err) => err);

export const fetchFeeds = () => apolloClient
  .query({ query: multiFeedsQuery });

export const useTeamsQuery = ({ sportId, queryOptions }) => {
  let query = null;
  if (sportId === SOCCER_ID) query = allSoccerTeamsQuery;
  else if (sportId === BASKETBALL_ID) query = allBasketballTeamsQuery;
  else if (sportId === FOOTBALL_ID) query = allFootballTeamsQuery;
  else if (sportId === BASEBALL_ID) query = allBaseballTeamsQuery;
  else if (sportId === ULTIMATE_ID) query = allUltimateTeamsQuery;
  else if (sportId === HOCKEY_ID) query = allHockeyTeamsQuery;
  else return Promise.resolve([]);
  return apolloClient
    .query({ query, variables: queryOptions, fetchPolicy: 'network-only' })
    .then((response) => response)
    .catch((err) => err);
};

export const useExtTeamsQuery = ({ sportId, queryOptions }) => {
  let query = null;
  if (sportId === SOCCER_ID) query = allExtSoccerTeamsQuery;
  else if (sportId === BASKETBALL_ID) query = allExtBasketballTeamsQuery;
  else if (sportId === FOOTBALL_ID) query = allExtFootballTeamsQuery;
  else if (sportId === BASEBALL_ID) query = allExtBaseballTeamsQuery;
  else if (sportId === ULTIMATE_ID) query = allExtUltimateTeamsQuery;
  else if (sportId === HOCKEY_ID) query = allExtHockeyTeamsQuery;
  else return Promise.resolve([]);
  return apolloClient
    .query({ query, variables: queryOptions, fetchPolicy: 'network-only' })
    .then((response) => response)
    .catch((err) => err);
};

export const findCompetitionTemplates = ({ sportId, sportTemplateId }) => {
  const queryOptions = {
    query: allCompetitionTemplatesQuery,
    variables: {
      filter: {
        sportId: {
          equalTo: sportId,
        },
      },
    },
    fetchPolicy: 'network-only',
  };

  return apolloClient.query(queryOptions)
    .then(({ data }) => map(
      data.competitions.nodes || [],
      ({
        competitionId,
        competitionName,
        region,
        appliedTemplates,
      }) => ({
        competitionId,
        competitionName,
        regionId: region?.regionId || 'N_A',
        regionName: region?.regionName || 'Unspecified',
        applied: !!find(appliedTemplates.nodes || [], { sportTemplateId }),
      }),
    ));
};

export const useAllFeedsQuery = () => apolloClient
  .query({ query: allFeedsQuery, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const getAllMarketGroups = (queryOptions) => apolloClient
  .query({
    query: marketGroupsQuery,
    variables: queryOptions,
  })
  .then((response) => response.data)
  .catch((err) => err);

export const findSidebarRegions = async ({ startDate, endDate, sportId }) => {
  try {
    if (!sportId) throw new Error('sportId parameter is required');

    const variables = {
      startDate,
      endDate,
      sportId,
    };

    if (!variables.startDate) {
      variables.startDate = computeStartsAt();
    }
    if (!variables.endDate) {
      delete variables.endDate;
    }

    const response = await apolloClient.query({
      query: sidebarRegionsQuery,
      variables,
      fetchPolicy: 'network-only',
    });
    return Object.values(reduce(
      response?.data?.sidebarRegions?.nodes ?? [],
      (mappedCompetitions, { region }) => ({
        ...mappedCompetitions,
        [region.regionId]: {
          ...region,
          sportId,
        },
      }),
      {},
    ));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findSidebarCompetitions = async ({
  startDate,
  endDate,
  sportId,
  regionId,
}) => {
  try {
    if (!sportId) throw new Error('sportId parameter is required');
    if (!regionId) throw new Error('regionId parameter is required');

    const variables = {
      startDate,
      endDate,
      sportId,
      regionId,
    };

    if (!variables.startDate) {
      variables.startDate = computeStartsAt();
    }
    if (!variables.endDate) {
      delete variables.endDate;
    }

    const response = await apolloClient.query({
      query: sidebarCompetitionsQuery,
      variables,
      fetchPolicy: 'network-only',
    });
    return map(
      response?.data?.sidebarCompetitions?.nodes ?? [],
      (competition) => ({
        ...competition,
        sportId,
        regionId,
      }),
    );
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchBookingSidebar = async ({
  startDate,
  endDate,
  sportId,
  regionId,
}) => {
  try {
    const data = {
      sports: [],
      regions: [],
      competitions: [],
    };

    const dateRange = {
      startDate,
      endDate,
    };
    if (dateRange.startDate && dateRange.endDate && dateRange.startDate === dateRange.endDate) {
      dateRange.endDate = format(endOfDay(parseISO(dateRange.endDate)), 'yyyy-LL-dd\'T\'HH:mm:ss');
    }
    if (!dateRange.startDate) {
      dateRange.startDate = computeStartsAt();
    }
    if (!dateRange.endDate) {
      delete dateRange.endDate;
    }

    data.sports = (await apolloClient.query({
      query: gql`query sidebarSports{
        sports: allSports(
          orderBy: SPORT_LABEL_ASC
          filter: {
            sportCoverageAvailablesBySportId: {
              some: {
                available: {
                  equalTo: true
                }
              }
            }
          }
          condition: {
            limbo: false
          }
          ) {
          nodes {
            sportId
            sportName
            sportLabel
          }
        }
      }`,
      fetchPolicy: 'network-only',
    }))?.data?.sports?.nodes ?? [];

    if (sportId) {
      data.regions = await findSidebarRegions({
        ...dateRange,
        sportId,
      });
    }

    if (regionId) {
      data.competitions = await findSidebarCompetitions({
        ...dateRange,
        sportId,
        regionId,
      });
    }

    return data;
  } catch (error) {
    console.error(error);
    return {
      sports: [],
      regions: [],
      competitions: [],
    };
  }
};

export const fetchSportById = async (sportId, abortController) => {
  try {
    const response = await apolloClient.query({
      query: gql`
        query SportById {
          sport: sportBySportId(sportId: "${sportId}") {
            sportName
          }
        }
      `,
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    });
    return response?.data?.sport;
  } catch {
    return null;
  }
};

export const fetchRegionById = async (regionId, abortController) => {
  try {
    const response = await apolloClient.query({
      query: gql`
        query RegionById {
          region: regionByRegionId(regionId: "${regionId}") {
            regionId
            regionName
          }
        }
      `,
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    });
    return response?.data?.region;
  } catch {
    return null;
  }
};

export const fetchCompetitionById = async (competitionId, abortController) => {
  try {
    const response = await apolloClient.query({
      query: gql`
        query CompetitionById {
          competition: competitionByCompetitionId(competitionId: "${competitionId}") {
            competitionId
            competitionName
            coverageAvailability: competitionCoverageAvailablesByCompetitionId {
              nodes {
                available
              }
            }
            feedConfiguration: competitionFeedConfigurationsByCompetitionId {
              nodes {
                feed
                priority
              }
            }
          }
        }
      `,
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
      fetchPolicy: 'network-only',
    });
    return response?.data?.competition;
  } catch {
    return null;
  }
};

export const fetchAppliedTemplates = async (eventId) => {
  try {
    const response = await apolloClient.query({
      query: appliedTemplatesQuery,
      variables: { id: eventId },
    });
    return response?.data?.event?.appliedTemplates ?? [];
  } catch {
    return [];
  }
};

export const getCompetitions = async (options, allSportsIds, pagination) => {
  const { subscribed, ...variables } = options ?? {};
  const allCompetitionsOptions = {
    first: pagination?.first,
    offset: pagination?.offset,
    filter: {
      limbo: {
        equalTo: false,
      },
      sportId: {
        in: variables.sportId ? [variables.sportId] : allSportsIds,
      },
    },
  };

  if (variables.search) {
    allCompetitionsOptions.filter = {
      ...allCompetitionsOptions.filter,
      competitionName: {
        includesInsensitive: variables.search,
      },
    };
  }
  if (variables.subscriptionFilter) {
    allCompetitionsOptions.filter = {
      ...allCompetitionsOptions.filter,
      subscribedCompetitionsByCompetitionIdExist: variables.subscriptionFilter.id === 1,
    };
  }
  if (variables.mappingFilter) {
    allCompetitionsOptions.filter = {
      ...allCompetitionsOptions.filter,
      competitionMappingsByIntIdExist: variables.mappingFilter.id === 1,
    };
  }

  if (subscribed) {
    const response = await apolloClient.query({
      query: getAllCompetitionsQuery,
      variables: allCompetitionsOptions,
      fetchPolicy: 'network-only',
    });
    return response?.data?.competitions ?? {};
  }

  const response = await apolloClient.query({
    query: getCompetitionsBetweenQuery,
    variables,
    fetchPolicy: 'network-only',
  });
  return response?.data?.competitions?.nodes ?? [];
};

export const getEvents = async (options) => {
  const variables = options ?? {};
  const response = await apolloClient.query({
    query: allEventsQuery,
    variables,
    fetchPolicy: 'network-only',
    context: {
      requestTrackerId: 'getEvents',
    },
  });
  const { nodes = [], totalCount = 0 } = response?.data?.events ?? {};
  return { nodes, totalCount };
};

export const fetchEligibleSports = () => apolloClient.query({
  query: gql`query eligibleSports{
    sports: allSports(
      orderBy: SPORT_LABEL_ASC
      filter: {
        sportCoverageAvailablesBySportId: {
          some: {
            available: {
              equalTo: true
            }
          }
        }
      }
      condition: {
        limbo: false
      }
      ) {
      nodes {
        sportId
        sportName
        sportLabel
      }
    }
  }`,
  fetchPolicy: 'network-only',
});

export const getInternalEventMappingDetails = ({ id, feed }) => apolloClient
  .query({
    query: eventMappingDetailsQuery,
    variables: {
      id,
      feed,
    },
    fetchPolicy: 'network-only',
  });

export const getExternalEventMappingDetails = ({ id, feed }) => apolloClient
  .query({
    query: eventMappingDetailsExtQuery,
    variables: {
      id,
      feed,
    },
    fetchPolicy: 'network-only',
  });

export const fetchEventSubscription = (id) => apolloClient
  .query({
    query: eventSubscriptionQuery,
    variables: { id },
    fetchPolicy: 'network-only',
  });

export const fetchCompetitionSubscription = (id) => apolloClient
  .query({
    query: competitionSubscriptionQuery,
    variables: { id },
    fetchPolicy: 'network-only',
  });

export const useManualResultingEventsQuery = (variables = {}) => apolloClient
  .query({ query: manualResultingEventsQuery, variables, fetchPolicy: 'network-only' })
  .then((response) => response.data?.events?.nodes || []);

export const useManualResultingEventMarketsQuery = (eventId) => apolloClient
  .query({
    query: manualResultingEventMarketsQuery,
    variables: { eventId },
    fetchPolicy: 'network-only',
    context: {
      requestTrackerId: 'useManualResultingEventMarketsQuery',
    },
  })
  .then((response) => response?.data?.event?.markets?.nodes || []);

export const getEventsForPlayerSetupSearch = async (options) => {
  const variables = options ?? {};
  const response = await apolloClient.query({
    query: allEventsSearchQuery,
    variables: {
      ...variables,
      filter: {
        ...(variables?.filter ?? {}),
      },
    },
    fetchPolicy: 'network-only',
  });
  const { nodes = [], totalCount = 0 } = response?.data?.events ?? {};
  return { nodes, totalCount };
};

export const getTeamSquad = async ({ teamId, sportId }) => {
  let query;
  switch (sportId) {
  case FOOTBALL_ID:
    query = footballTeamByTeamIdQuery;
    break;
  case BASKETBALL_ID:
    query = basketballTeamByTeamIdQuery;
    break;
  case HOCKEY_ID:
    query = hockeyTeamByTeamIdQuery;
    break;
  case BASEBALL_ID:
    query = baseballTeamByTeamIdQuery;
    break;
  default:
    query = null;
  }

  if (!query) {
    console.log('Supported sports are Football, Basketball, Hockey and Baseball');
    return null;
  }
  try {
    const response = await apolloClient.query({
      query,
      variables: { teamId },
    });
    return response?.data?.team || [];
  } catch {
    return [];
  }
};

export const findTradingEventById = async (id) => {
  try {
    const response = await apolloClient
      .query({
        query: tradingEventQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      });
    return response.data.event;
  } catch (err) {
    console.log('err');
    throw Error(err);
  }
};

export const findAuditLogEventById = async (id) => {
  try {
    const response = await apolloClient
      .query({
        query: auditLogEventQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      });
    return response.data.event;
  } catch (err) {
    console.log('err');
    throw Error(err);
  }
};

export const getAllOperators = () => apolloClient
  .query({
    query: allOperatorsQuery,
    context: {
      headers: {
        'x-admin-for': '*', // Special case where we need to define super admin parameter to fetch all of the operators
      },
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response.data?.allOperators?.operators || []);

export const useFeedsPerSportQuery = ({ queryOptions }) => apolloClient
  .query({ query: feedsPerSportQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

// Manual edit
export const useManualEditTeamsQuery = async ({ sportId, queryOptions }) => {
  let query = null;
  if (sportId === FOOTBALL_ID) query = allManualEditFootballTeamsQuery;
  else if (sportId === BASEBALL_ID) query = allManualEditBaseballTeamsQuery;
  else if (sportId === BASKETBALL_ID) query = allManualEditBasketballTeamsQuery;
  else if (sportId === HOCKEY_ID) query = allManualEditHockeyTeamsQuery;
  else if (sportId === ULTIMATE_ID) query = allManualEditUltimateTeamsQuery;
  else return Promise.resolve([]);
  return apolloClient
    .query({ query, variables: queryOptions, fetchPolicy: 'network-only' })
    .then((response) => response.data?.teams)
    .catch((err) => err);
};

export const useSearchPlayerBySportQuery = async ({ sportId, queryOptions }) => {
  let query = null;
  if (sportId === FOOTBALL_ID) query = allManualEditFootballPlayersQuery;
  else if (sportId === BASEBALL_ID) query = allManualEditBaseballPlayersQuery;
  else if (sportId === BASKETBALL_ID) query = allManualEditBasketballPlayersQuery;
  else if (sportId === HOCKEY_ID) query = allManualEditHockeyPlayersQuery;
  else if (sportId === ULTIMATE_ID) query = allManualEditUltimatePlayersQuery;
  else return Promise.resolve([]);
  return apolloClient
    .query({ query, variables: queryOptions, fetchPolicy: 'network-only' })
    .then((response) => response.data?.players)
    .catch((err) => err);
};
export const useManualEditPlayersQuery = async ({ sportId, queryOptions }) => {
  let query = null;
  if (sportId === FOOTBALL_ID) query = allManualEditFootballPlayersQuery;
  else if (sportId === BASEBALL_ID) query = allManualEditBaseballPlayersQuery;
  else if (sportId === BASKETBALL_ID) query = allManualEditBasketballPlayersQuery;
  else if (sportId === HOCKEY_ID) query = allManualEditHockeyPlayersQuery;
  else if (sportId === ULTIMATE_ID) query = allManualEditUltimatePlayersQuery;
  else return Promise.resolve([]);
  return apolloClient
    .query({ query, variables: queryOptions, fetchPolicy: 'network-only' })
    .then((response) => response.data?.players)
    .catch((err) => err);
};

export const getMarketDisplayConfigurations = async ({ sportId = '' }) => {
  try {
    const response = await apolloClient.query({
      query: tradingMarketDisplayConfigurationsQuery,
      variables: {
        sportId,
      },
      context: {
        requestTrackerId: 'getMarketDisplayConfigurations',
      },
      fetchPolicy: 'network-only',
    });
    return {
      marketDisplayConfigurations: response?.data?.allMarketDisplayConfigurations?.nodes || [],
    };
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findMultiviewEvents = (options) => apolloClient
  .query({
    query: multiviewEventsQuery,
    variables: options,
    fetchPolicy: 'network-only',
    context: {
      requestTrackerId: 'customMultiviewEvents',
    },
  });

export const fetchMultiviewSearchResults = (options) => apolloClient
  .query({
    query: multiviewSearchQuery,
    variables: options,
    fetchPolicy: 'network-only',
    context: {
      requestTrackerId: 'multiviewSearch',
    },
  });

export const findMultiviewDrawer = ({ eventId }) => apolloClient
  .query({
    query: multiviewDrawerQuery,
    variables: { eventId },
    fetchPolicy: 'network-only',
    context: {
      requestTrackerId: 'findMultiviewDrawer',
    },
  });

export const loadMarketDisplayConfigurations = (queryOptions) => apolloClient
  .query({ query: allMarketDisplayConfigurationsQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const loadSelectionDisplayConfigurations = (queryOptions) => apolloClient
  .query({ query: allSelectionDisplayConfigurationsQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const loadAllSportsMarketDisplayConfigurations = () => apolloClient
  .query({ query: allSportsMarketDisplayConfigurationsQuery, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const loadMarketParameters = (queryOptions) => apolloClient
  .query({ query: allMarketParametersQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const loadSelectionParameters = (queryOptions) => apolloClient
  .query({ query: allSelectionParametersQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const findFeedConfiguration = (competitionId) => apolloClient
  .query({
    query: feedConfigurationQuery,
    variables: { competitionId },
    fetchPolicy: 'network-only',
  })
  .then((response) => {
    const { competition } = response.data;
    const configurations = competition?.feedConfiguration?.nodes || [];
    return map(configurations, (configuration, index) => ({
      ...configuration,
      competitionId: competition?.competitionId,
      priority: index,
    }));
  });

export const loadAllMarketGroupsList = (queryOptions) => apolloClient
  .query({ query: allMarketGroupsListQuery, variables: queryOptions, fetchPolicy: 'network-only' });

export const useSearchAllMarketCodesQuery = async ({ queryOptions }) => apolloClient
  .query({ query: allMarketCodesQuery, variables: queryOptions, fetchPolicy: 'network-only' })
  .then((response) => response.data?.marketCodes)
  .catch((err) => err);

export const getCompetitionsWithEvents = async ({ sportId = '', startDate = '' }) => {
  try {
    const response = await apolloClient.query({
      query: sportCompetitionsWithEventQuery,
      variables: {
        sportId,
        startDate,
      },
      fetchPolicy: 'network-only',
    });
    return response?.data?.competitions?.nodes ?? [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const queryOddsCheckerEvents = (options = {}) => apolloClient.query({
  query: oddsCheckerEventsQuery,
  variables: {
    ...options,
    filter: {
      ...(options.filter || {}),
      /* eventIsCoveredsByEventId: {
        some: {
          available: {
            equalTo: true,
          },
        },
      }, */
    },
  },
  fetchPolicy: 'network-only',
});

export const fetchPrimarySportFeeds = (sportId) => apolloClient
  .query({
    query: primarySportFeeds,
    variables: { sportId },
    fetchPolicy: 'network-only',
    context: {
      requestTrackerId: 'fetchPrimarySportFeeds',
    },
  });

export const fetchSportCompetitionConfigurations = ({
  sportId,
  search,
  order,
  first,
  offset,
}) => apolloClient
  .query({
    query: sportCompetitionConfigurations,
    variables: pickBy({
      sportId,
      search,
      order,
      first,
      offset,
    }, identity),
    fetchPolicy: 'network-only',
    context: {
      requestTrackerId: 'fetchSportCompetitionConfigurations',
    },
  });

export const loadAllBaseballParks = () => apolloClient
  .query({ query: allBaseballParksQuery, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const findSportsAndCompetitions = ({ sportId = '', searchValue = '' } = {}) => apolloClient
  .query({
    query: sportsAndCompetitionsQuery,
    variables: pickBy({
      sportId,
      searchValue,
    }, identity),
    fetchPolicy: 'network-only',
  })
  .then((response) => ({
    sports: response.data?.sports?.nodes || [],
    competitions: response.data?.competitions?.nodes || [],
  }));

export const findSportById = (sportId) => apolloClient
  .query({
    query: sportByIdQuery,
    variables: {
      id: sportId,
    },
  })
  .then((response) => response.data?.sport || null)
  .catch(() => null);

export const findCompetitions = ({ sportId = '', searchValue = '' }) => apolloClient
  .query({
    query: competitionsQuery,
    variables: pickBy({
      sportId,
      searchValue,
    }, identity),
    context: {
      requestTrackerId: 'findCompetitions',
    },
    fetchPolicy: 'network-only',
  })
  .then((response) => response.data?.competitions?.nodes || []);

export const findCompetitionById = (competitionId) => apolloClient
  .query({
    query: competitionByIdQuery,
    variables: {
      id: competitionId,
    },
  })
  .then((response) => response.data?.competition || null)
  .catch(() => null);

export const findAllCompetitionsByIds = (competitionIds) => apolloClient
  .query({
    query: allCompetitionsByIdQuery,
    variables: {
      ids: competitionIds,
    },
  })
  .then((response) => response.data?.competitions?.nodes || []);

export const findCompetitionsBySportId = ({ sportId, search }) => apolloClient
  .query({
    query: competitionsBySportQuery,
    variables: { sportId, search },
    fetchPolicy: 'network-only',
    context: { requestTrackerId: 'findCompetitionsBySportId' },
  })
  .then((response) => response.data.allCompetitions?.nodes || []);

export const findSimpleTeamsBySportId = ({ sportId, search }) => {
  const commonOptions = {
    fetchPolicy: 'network-only',
    variables: {
      search,
    },
    context: {
      requestTrackerId: `findSimpleTeamsBySportId-${sportId}`,
    },
  };
  switch (sportId) {
  case FOOTBALL_ID:
    return apolloClient.query({
      ...commonOptions,
      query: simpleFootballTeamsQuery,
    });
  case BASEBALL_ID:
    return apolloClient.query({
      ...commonOptions,
      query: simpleBaseballTeamsQuery,
    });
  case BASKETBALL_ID:
    return apolloClient.query({
      ...commonOptions,
      query: simpleBasketballTeamsQuery,
    });
  case HOCKEY_ID:
    return apolloClient.query({
      ...commonOptions,
      query: simpleHockeyTeamsQuery,
    });
  default:
    console.warn(`Unsupported sport ID for "findSimpleTeamsBySportId" query: ${sportId}`);
    return Promise.resolve({
      data: {
        teams: {
          nodes: [],
        },
      },
    });
  }
};

export const findEditMarketListData = (sportId) => apolloClient
  .query({
    query: editMarketListQuery,
    variables: { sportId },
    fetchPolicy: 'network-only',
    context: { requestTrackerId: 'findEditMarketListData' },
  });

export const findUserConfiguration = () => apolloClient
  .query({
    query: userConfigurationQuery,
    variables: {},
    fetchPolicy: 'network-only',
    context: { requestTrackerId: 'findUserConfiguration' },
  })
  .then((response) => response.data?.userConfiguration?.nodes?.[0] || {});
